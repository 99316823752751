import { Trans } from "@ui/i18n/trans";
import clsx from "clsx";
import React, { memo } from "react";
import { Product } from "./product";
import { ProductFeatureList } from "./pricing-table/product-feature-list";
import { FormattedPrice } from "./formatted-price";
import { opacityAnimation } from "@ui/animation/opacity-animation";
import { motion as m } from "framer-motion";
import { findBestPrice, UpsellBillingCycle } from "./pricing-table/find-best-price";

interface PlanItemProps {
  plan: Product;
  PlanButton: React.ReactElement;
  isCurrentPlan?: boolean;
  selectedPeriod?: UpsellBillingCycle
}

export const PlanItem = memo((props: PlanItemProps) => {
  const {
    plan,
    PlanButton,
    isCurrentPlan = false,
    selectedPeriod = 'monthly',
  } = props;

  const price = findBestPrice(selectedPeriod, plan.prices);
  const isRecommended = plan.recommended ?? false;

  const currentPlanTranslation = <Trans message="Current Plan" />;

  return (
    <m.div
      {...opacityAnimation}
      className="
        relative w-full sm:w-[calc(50%-16px)] md:w-[calc(33.333333%-16px)] max-w-320
      "
    >
      {/* tweakable drop shadow */}
      <div className="absolute inset-0 z-0 bg-primary opacity-75 rounded-2xl drop-shadow-2xl" />
      {isRecommended && <div className="absolute inset-32 mt-32 z-0 bg-primary blur-2xl" />}

      <div
        className={clsx(`
          relative z-10 overflow-hidden rounded-xl bg-background px-24 py-30
          dark:bg-alt sm:p-36 lg:py-30 xl:p-42 h-full flex flex-col`,
          isRecommended && 'ring-4 ring-primary'
        )}
      >
        {isRecommended && (
          <span
            className="
              absolute top-0 left-0 z-0 px-16 pt-3 text-white bg-primary uppercase
              inline-flex items-start h-24 rounded-br-2xl text-xs font-semibold
            "
          >
            <Trans message="Most Popular" />
          </span>
        )}

        {isCurrentPlan && (
          <span
            className="
              absolute top-20 right-16 z-0 px-12 py-3 bg-alt text-muted
              inline-flex items-center h-24 rounded-full text-sm font-semibold
            "
          >
            {currentPlanTranslation}
          </span>
        )}

        <div className="mb-24">
          <span className="flex items-end text-2xl font-medium">
            <Trans message={plan.name} />
          </span>
          {plan.description && (
            <p className="text-muted text-base mt-4">
              <Trans message={plan.description} />
            </p>
          )}
        </div>

        {price ? (
          <FormattedPrice
            className='flex items-end gap-8 text-4xl'
            priceClassName="font-bold -tracking-[1px]"
            periodClassName="text-sm font-normal text-muted leading-4"
            variant="separateLine"
            price={price}
          />
        ) : (
          <h2 className="flex items-end gap-8 text-4xl font-semibold xl:text-[42px]">
            <Trans message="Free" />
          </h2>
        )}

        <ProductFeatureList product={plan} />

        <div className="mt-auto pt-36">
          {PlanButton}
        </div>
      </div>
    </m.div>
  );
});