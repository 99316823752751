import {Trans} from '@ui/i18n/trans';
import {Product} from '../product';
import {CheckIcon} from '@ui/icons/material/Check';

interface FeatureListProps {
  product: Product;
}

export function ProductFeatureList({product}: FeatureListProps) {
  if (!product.feature_list.length) return null;

  return (
    <div className="mt-32">
      <h5 className="mb-10 text-lg font-medium">
        <Trans message="Includes" />
      </h5>
      <div className="flex flex-col gap-10">
        {product.feature_list.map(feature => (
          <p className="text-base text-muted">
            <Trans message={feature} />
          </p>
        ))}
      </div>
    </div>
  );
}
