import {useProducts} from './use-products';
import {Button} from '@ui/buttons/button';
import {Trans} from '@ui/i18n/trans';
import {ForumIcon} from '@ui/icons/material/Forum';
import {Navbar} from '../../ui/navigation/navbar/navbar';
import {Link} from 'react-router-dom';
import {Footer} from '../../ui/footer/footer';
import {useState} from 'react';
import {UpsellBillingCycle} from './find-best-price';
import {StaticPageTitle} from '../../seo/static-page-title';
import {PricingTable} from '@common/billing/pricing-table/pricing-table';
import { ShieldIcon } from '@ui/icons/material/Shield';
import { StorageIcon } from '@ui/icons/material/Storage';
import { ShareIcon } from '@ui/icons/material/Share';
import { TerminalIcon } from '../../ui/library/icons/material/Terminal';
import { LanguageIcon } from '@ui/icons/material/Language';
import { DnsIcon } from '@ui/icons/material/Dns';

export function PricingPage() {
  const query = useProducts('pricingPage');
  const [selectedCycle, setSelectedCycle] =
    useState<UpsellBillingCycle>('yearly');

  /* <BillingCycleRadio
    products={query.data?.products}
    selectedCycle={selectedCycle}
    onChange={setSelectedCycle}
    className="mb-40 flex justify-center md:mb-70"
    size="lg"
  /> */


  return (
    <>
      <StaticPageTitle>
        <Trans message="Pricing" />
      </StaticPageTitle>
      <Navbar
        color="bg"
        darkModeColor="transparent"
        border="border-b"
        menuPosition="pricing-table-page"
      />
      <section id="pricing" className="relative z-20 overflow-hidden py-80">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div className="mx-auto mb-64 max-w-580 text-center">
                <h2 className="mb-8 text-3xl font-bold text-dark dark:text-white sm:text-4xl md:text-[40px] md:leading-[1.2]">
                  <Trans message="Choose the right plan for you" />
                </h2>
                <p className="text-2xl text-muted">
                  <Trans message="Fully featured, just pay for storage." />
                </p>
              </div>
            </div>
          </div>

          <PricingTable
            selectedCycle={selectedCycle}
            productLoader="pricingPage"
          />

          <div className="text-center text-muted mt-40 -mb-20">
            <Trans message="Need even more storage?" />{' '}
            <Link to="/contact" className="text-primary">
              <Trans message="Contact us" />
            </Link>
          </div>

        </div>
      </section>
      <FeatureSection />
      <Footer className="container mx-auto flex-shrink-0 px-24" />
    </>
  )
}


function FeatureSection() {
  const points = [
    {
      IconElement: StorageIcon,
      title: 'File Manager',
      content: `
        Our minimal interface helps you focus on what matters most: storing, accessing, and sharing your files.
      `,
    },
    {
      IconElement: ShareIcon,
      title: 'Generate Share Links',
      content: `
        Share your files and folders via a secure link. You can also set an expiration date, password, and even prevent downloads.
      `,
    },
    {
      IconElement: DnsIcon,
      title: 'Collaborative Workspaces',
      content: `
        Create a virtual "drive" to contribute and manage files with your team in a shared space, with granular permissions.
      `,
    },
    {
      IconElement: ShieldIcon,
      title: 'Secure Encryption',
      content: `
        All files are encrypted at rest and in transit. We use the latest encryption standards to keep your data safe.
      `,
    },
    {
      IconElement: LanguageIcon,
      title: 'Unmetered Data Transfer',
      content: `
        No hard data transfer limits and no hidden charges. Upload and download your files as much as you want, whenever you want.
      `,
    },
    {
      IconElement: TerminalIcon,
      title: '*API Access',
      content: `
        For plans of 2TB+, you can request access to our API to automate tasks and integrate with other services.
      `,
    },
  ];

  return (
    <section className="relative z-20 overflow-hidden pb-8 pt-36 lg:pb-64 lg:pt-80">
      <div className="container mx-auto">
        <div className="flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-76 max-w-512 text-center">
              <h2 className="mb-8 text-3xl font-bold text-dark dark:text-white sm:text-4xl md:text-[40px] md:leading-[1.2]">
                <Trans message="Batteries included" />
              </h2>
              <p className="text-2xl text-muted">
                <Trans message="Our plans are storage quotas, not features." />*
              </p>
            </div>
          </div>
        </div>
        <div className="-mx-4 grid grid-cols-1 gap-24 md:gap-40 sm:grid-cols-2 md:grid-cols-3">
          {points.map((point, index) => (
            <div className="flex mb-24" key={index}>
              <div
                className="
                  mr-16 flex h-50 w-full max-w-50 items-center justify-center rounded-xl
                  bg-primary text-white sm:mr-20 sm:h-60 sm:max-w-60
                "
              >
                {point.IconElement && <point.IconElement className="w-32 h-32" />}
              </div>
              <div className="w-full">
                <h3 className="mb-5 text-xl font-semibold sm:text-lg xl:text-xl">
                  <Trans message={point.title} />
                </h3>
                <p className="text-base">
                  <Trans message={point.content} />
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <span className="absolute left-4 top-4 -z-[1]">
          <svg width="48" height="134" viewBox="0 0 48 134" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="45.6673" cy="132" r="1.66667" transform="rotate(180 45.6673 132)" className="fill-primary" />
            <circle cx="45.6673" cy="117.333" r="1.66667" transform="rotate(180 45.6673 117.333)" className="fill-primary" />
            <circle cx="45.6673" cy="102.667" r="1.66667" transform="rotate(180 45.6673 102.667)" className="fill-primary" />
            <circle cx="45.6673" cy="88.0001" r="1.66667" transform="rotate(180 45.6673 88.0001)" className="fill-primary" />
            <circle cx="45.6673" cy="73.3335" r="1.66667" transform="rotate(180 45.6673 73.3335)" className="fill-primary" />
            <circle cx="45.6673" cy="45.0001" r="1.66667" transform="rotate(180 45.6673 45.0001)" className="fill-primary" />
            <circle cx="45.6673" cy="16.0001" r="1.66667" transform="rotate(180 45.6673 16.0001)" className="fill-primary" />
            <circle cx="45.6673" cy="59.0001" r="1.66667" transform="rotate(180 45.6673 59.0001)" className="fill-primary" />
            <circle cx="45.6673" cy="30.6668" r="1.66667" transform="rotate(180 45.6673 30.6668)" className="fill-primary" />
            <circle cx="45.6673" cy="1.66683" r="1.66667" transform="rotate(180 45.6673 1.66683)" className="fill-primary" />
            <circle cx="31.0013" cy="132" r="1.66667" transform="rotate(180 31.0013 132)" className="fill-primary" />
            <circle cx="31.0013" cy="117.333" r="1.66667" transform="rotate(180 31.0013 117.333)" className="fill-primary" />
            <circle cx="31.0013" cy="102.667" r="1.66667" transform="rotate(180 31.0013 102.667)" className="fill-primary" />
            <circle cx="31.0013" cy="88.0001" r="1.66667" transform="rotate(180 31.0013 88.0001)" className="fill-primary" />
            <circle cx="31.0013" cy="73.3335" r="1.66667" transform="rotate(180 31.0013 73.3335)" className="fill-primary" />
            <circle cx="31.0013" cy="45.0001" r="1.66667" transform="rotate(180 31.0013 45.0001)" className="fill-primary" />
            <circle cx="31.0013" cy="16.0001" r="1.66667" transform="rotate(180 31.0013 16.0001)" className="fill-primary" />
            <circle cx="31.0013" cy="59.0001" r="1.66667" transform="rotate(180 31.0013 59.0001)" className="fill-primary" />
            <circle cx="31.0013" cy="30.6668" r="1.66667" transform="rotate(180 31.0013 30.6668)" className="fill-primary" />
            <circle cx="31.0013" cy="1.66683" r="1.66667" transform="rotate(180 31.0013 1.66683)" className="fill-primary" />
            <circle cx="16.3333" cy="132" r="1.66667" transform="rotate(180 16.3333 132)" className="fill-primary" />
            <circle cx="16.3333" cy="117.333" r="1.66667" transform="rotate(180 16.3333 117.333)" className="fill-primary" />
            <circle cx="16.3333" cy="102.667" r="1.66667" transform="rotate(180 16.3333 102.667)" className="fill-primary" />
            <circle cx="16.3333" cy="88.0001" r="1.66667" transform="rotate(180 16.3333 88.0001)" className="fill-primary" />
            <circle cx="16.3333" cy="73.3335" r="1.66667" transform="rotate(180 16.3333 73.3335)" className="fill-primary" />
            <circle cx="16.3333" cy="45.0001" r="1.66667" transform="rotate(180 16.3333 45.0001)" className="fill-primary" />
            <circle cx="16.3333" cy="16.0001" r="1.66667" transform="rotate(180 16.3333 16.0001)" className="fill-primary" />
            <circle cx="16.3333" cy="59.0001" r="1.66667" transform="rotate(180 16.3333 59.0001)" className="fill-primary" />
            <circle cx="16.3333" cy="30.6668" r="1.66667" transform="rotate(180 16.3333 30.6668)" className="fill-primary" />
            <circle cx="16.3333" cy="1.66683" r="1.66667" transform="rotate(180 16.3333 1.66683)" className="fill-primary" />
            <circle cx="1.66732" cy="132" r="1.66667" transform="rotate(180 1.66732 132)" className="fill-primary" />
            <circle cx="1.66732" cy="117.333" r="1.66667" transform="rotate(180 1.66732 117.333)" className="fill-primary" />
            <circle cx="1.66732" cy="102.667" r="1.66667" transform="rotate(180 1.66732 102.667)" className="fill-primary" />
            <circle cx="1.66732" cy="88.0001" r="1.66667" transform="rotate(180 1.66732 88.0001)" className="fill-primary" />
            <circle cx="1.66732" cy="73.3335" r="1.66667" transform="rotate(180 1.66732 73.3335)" className="fill-primary" />
            <circle cx="1.66732" cy="45.0001" r="1.66667" transform="rotate(180 1.66732 45.0001)" className="fill-primary" />
            <circle cx="1.66732" cy="16.0001" r="1.66667" transform="rotate(180 1.66732 16.0001)" className="fill-primary" />
            <circle cx="1.66732" cy="59.0001" r="1.66667" transform="rotate(180 1.66732 59.0001)" className="fill-primary" />
            <circle cx="1.66732" cy="30.6668" r="1.66667" transform="rotate(180 1.66732 30.6668)" className="fill-primary" />
            <circle cx="1.66732" cy="1.66683" r="1.66667" transform="rotate(180 1.66732 1.66683)" className="fill-primary" />
          </svg>
        </span>
        <span className="absolute bottom-4 right-4 -z-[1]">
          <svg width="48" height="134" viewBox="0 0 48 134" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="45.6673" cy="132" r="1.66667" transform="rotate(180 45.6673 132)" className="fill-primary" />
            <circle cx="45.6673" cy="117.333" r="1.66667" transform="rotate(180 45.6673 117.333)" className="fill-primary" />
            <circle cx="45.6673" cy="102.667" r="1.66667" transform="rotate(180 45.6673 102.667)" className="fill-primary" />
            <circle cx="45.6673" cy="88.0001" r="1.66667" transform="rotate(180 45.6673 88.0001)" className="fill-primary" />
            <circle cx="45.6673" cy="73.3333" r="1.66667" transform="rotate(180 45.6673 73.3333)" className="fill-primary" />
            <circle cx="45.6673" cy="45.0001" r="1.66667" transform="rotate(180 45.6673 45.0001)" className="fill-primary" />
            <circle cx="45.6673" cy="16.0001" r="1.66667" transform="rotate(180 45.6673 16.0001)" className="fill-primary" />
            <circle cx="45.6673" cy="59.0001" r="1.66667" transform="rotate(180 45.6673 59.0001)" className="fill-primary" />
            <circle cx="45.6673" cy="30.6668" r="1.66667" transform="rotate(180 45.6673 30.6668)" className="fill-primary" />
            <circle cx="45.6673" cy="1.66683" r="1.66667" transform="rotate(180 45.6673 1.66683)" className="fill-primary" />
            <circle cx="31.0006" cy="132" r="1.66667" transform="rotate(180 31.0006 132)" className="fill-primary" />
            <circle cx="31.0006" cy="117.333" r="1.66667" transform="rotate(180 31.0006 117.333)" className="fill-primary" />
            <circle cx="31.0006" cy="102.667" r="1.66667" transform="rotate(180 31.0006 102.667)" className="fill-primary" />
            <circle cx="31.0006" cy="88.0001" r="1.66667" transform="rotate(180 31.0006 88.0001)" className="fill-primary" />
            <circle cx="31.0008" cy="73.3333" r="1.66667" transform="rotate(180 31.0008 73.3333)" className="fill-primary" />
            <circle cx="31.0008" cy="45.0001" r="1.66667" transform="rotate(180 31.0008 45.0001)" className="fill-primary" />
            <circle cx="31.0008" cy="16.0001" r="1.66667" transform="rotate(180 31.0008 16.0001)" className="fill-primary" />
            <circle cx="31.0008" cy="59.0001" r="1.66667" transform="rotate(180 31.0008 59.0001)" className="fill-primary" />
            <circle cx="31.0008" cy="30.6668" r="1.66667" transform="rotate(180 31.0008 30.6668)" className="fill-primary" />
            <circle cx="31.0008" cy="1.66683" r="1.66667" transform="rotate(180 31.0008 1.66683)" className="fill-primary" />
            <circle cx="16.3341" cy="132" r="1.66667" transform="rotate(180 16.3341 132)" className="fill-primary" />
            <circle cx="16.3341" cy="117.333" r="1.66667" transform="rotate(180 16.3341 117.333)" className="fill-primary" />
            <circle cx="16.3341" cy="102.667" r="1.66667" transform="rotate(180 16.3341 102.667)" className="fill-primary" />
            <circle cx="16.3341" cy="88.0001" r="1.66667" transform="rotate(180 16.3341 88.0001)" className="fill-primary" />
            <circle cx="16.3338" cy="73.3333" r="1.66667" transform="rotate(180 16.3338 73.3333)" className="fill-primary" />
            <circle cx="16.3338" cy="45.0001" r="1.66667" transform="rotate(180 16.3338 45.0001)" className="fill-primary" />
            <circle cx="16.3338" cy="16.0001" r="1.66667" transform="rotate(180 16.3338 16.0001)" className="fill-primary" />
            <circle cx="16.3338" cy="59.0001" r="1.66667" transform="rotate(180 16.3338 59.0001)" className="fill-primary" />
            <circle cx="16.3338" cy="30.6668" r="1.66667" transform="rotate(180 16.3338 30.6668)" className="fill-primary" />
            <circle cx="16.3338" cy="1.66683" r="1.66667" transform="rotate(180 16.3338 1.66683)" className="fill-primary" />
            <circle cx="1.66732" cy="132" r="1.66667" transform="rotate(180 1.66732 132)" className="fill-primary" />
            <circle cx="1.66732" cy="117.333" r="1.66667" transform="rotate(180 1.66732 117.333)" className="fill-primary" />
            <circle cx="1.66732" cy="102.667" r="1.66667" transform="rotate(180 1.66732 102.667)" className="fill-primary" />
            <circle cx="1.66732" cy="88.0001" r="1.66667" transform="rotate(180 1.66732 88.0001)" className="fill-primary" />
            <circle cx="1.66732" cy="73.3333" r="1.66667" transform="rotate(180 1.66732 73.3333)" className="fill-primary" />
            <circle cx="1.66732" cy="45.0001" r="1.66667" transform="rotate(180 1.66732 45.0001)" className="fill-primary" />
            <circle cx="1.66732" cy="16.0001" r="1.66667" transform="rotate(180 1.66732 16.0001)" className="fill-primary" />
            <circle cx="1.66732" cy="59.0001" r="1.66667" transform="rotate(180 1.66732 59.0001)" className="fill-primary" />
            <circle cx="1.66732" cy="30.6668" r="1.66667" transform="rotate(180 1.66732 30.6668)" className="fill-primary" />
            <circle cx="1.66732" cy="1.66683" r="1.66667" transform="rotate(180 1.66732 1.66683)" className="fill-primary" />
          </svg>
        </span>
      </div>
    </section>
  );
}

function ContactSection() {
  return (
    <div className="my-20 p-24 text-center md:my-80">
      <ForumIcon size="xl" className="text-muted" />
      <div className="my-8 md:text-lg">
        <Trans message="Do you have any questions about PRO accounts?" />
      </div>
      <div className="mb-24 mt-20 text-sm md:mt-0 md:text-base">
        <Trans message="Our support team will be happy to assist you." />
      </div>
      <Button variant="flat" color="primary" elementType={Link} to="/contact">
        <Trans message="Contact us" />
      </Button>
    </div>
  );
}
