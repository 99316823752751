import React from 'react';
import {Price} from './price';
import {Trans} from '@ui/i18n/trans';
import clsx from 'clsx';
import {FormattedCurrency} from '@ui/i18n/formatted-currency';

interface FormattedPriceProps {
  price?: Omit<Price, 'id'>;
  variant?: 'slash' | 'separateLine';
  className?: string;
  priceClassName?: string;
  periodClassName?: string;
}
export function FormattedPrice({
  price,
  variant = 'slash',
  className,
  priceClassName,
  periodClassName,
}: FormattedPriceProps) {
  if (!price) return null;

  const translatedInterval = <Trans message={price.interval} />;

  return (
    <div className={className}>
      <span className={priceClassName}>
        <FormattedCurrency
          value={price.amount / (price.interval_count ?? 1)}
          currency={price.currency}
        />
      </span>
      <span className={periodClassName}>
        {variant === 'slash' ? (
          <>{' '}/ {translatedInterval}</>
        ) : (
          <><Trans message="per" /> <br /> {translatedInterval}</>
        )}
      </span>
    </div>
  )
}
