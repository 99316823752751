import clsx from 'clsx';
import {LandingPageContent} from './landing-page-content';
import {Navbar} from '@common/ui/navigation/navbar/navbar';
import {Button, ButtonProps} from '@ui/buttons/button';
import {IconButton} from '@ui/buttons/icon-button';
import {KeyboardArrowDownIcon} from '@ui/icons/material/KeyboardArrowDown';
import {Footer} from '@common/ui/footer/footer';
import {Trans} from '@ui/i18n/trans';
import {AdHost} from '@common/admin/ads/ad-host';
import {Link} from 'react-router-dom';
import {createSvgIconFromTree} from '@ui/icons/create-svg-icon';
import {MenuItemConfig} from '@common/menus/menu-config';
import {Fragment} from 'react';
import {DefaultMetaTags} from '@common/seo/default-meta-tags';
import {useTrans} from '@ui/i18n/use-trans';
import {useSettings} from '@ui/settings/use-settings';
import { CloudUploadIcon } from '@ui/icons/material/CloudUpload';
import { SvgIconProps } from '@ui/icons/svg-icon';
import { LinkIcon } from '@ui/icons/material/Link';
import { ShieldIcon } from '@ui/icons/material/Shield';
import { DnsIcon } from '@ui/icons/material/Dns';
import { LockIcon } from '@ui/icons/material/Lock';
import { DevicesIcon } from '@ui/icons/material/Devices';
import { SupportIcon } from '@ui/icons/material/Support';
import { StarIcon } from '@ui/icons/material/Star';

interface ContentProps {
  content: LandingPageContent;
}
export function LandingPage() {
  const settings = useSettings();
  const homepage = settings.homepage as {appearance: LandingPageContent};

  return (
    <Fragment>
      <DefaultMetaTags />
      <div className="overflow-x-hidden max-w-full">
        <HeroHeader content={homepage.appearance} />
        <AdHost slot="landing-top" className="mb-14 px-14 md:mb-60" />
        <PrimaryFeatures content={homepage.appearance} />
        <SecondaryFeatures content={homepage.appearance} />
        <BottomCta content={homepage.appearance} />
        <FAQSection content={homepage.appearance} />
        <Footer className="landing-container" />
      </div>
    </Fragment>
  );
}

function HeroHeader({
  content: {
    headerTitle,
    headerSubtitle,
    headerImage,
    headerImageOpacity,
    actions,
    headerOverlayColor1,
    headerOverlayColor2,
  },
}: ContentProps) {
  const {trans} = useTrans();

  let overlayBackground = undefined;

  if (headerOverlayColor1 && headerOverlayColor2) {
    overlayBackground = `linear-gradient(45deg, ${headerOverlayColor1} 0%, ${headerOverlayColor2} 100%)`;
  } else if (headerOverlayColor1) {
    overlayBackground = headerOverlayColor1;
  } else if (headerOverlayColor2) {
    overlayBackground = headerOverlayColor2;
  }

  const scrollToFeatures = () => {
    document.getElementById('primary-features')?.scrollIntoView({behavior: 'smooth'});
  }

  return (
    <div id="home" className="relative overflow-hidden bg-primary pt-[120px] md:pt-[130px] lg:pt-[160px]">
      <div className="fixed top-0 w-full z-50 bg-primary bg-opacity-95 backdrop-blur-md">
        <Navbar
          color="transparent"
          className="flex-shrink-0 container !bg-transparent !border-0"
          menuPosition="homepage-navbar"
          showThemeSelector={true}
        />
      </div>
      <div className="container">
        <div className="flex flex-wrap items-center">
          <div className="w-full px-4 mb-16">
            <div className="hero-content wow fadeInUp mx-auto max-w-780 text-center" data-wow-delay=".2s"  style={{ visibility: 'visible', animationDelay: '0.2s' }}>
              <h1 className="mb-16 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug lg:text-5xl lg:leading-[1.2]">
                <Trans message="Store and share your files online" />
              </h1>
              <p className="mx-auto mb-32 max-w-620 text-base font-medium text-white sm:text-lg sm:leading-[1.44]">
                <Trans message="
                  Simple online file storage in the cloud for individuals and freelancers.
                  Upload, view, share and even collaborate from any device.
                " />
              </p>
              <ul className="mb-32 flex flex-wrap items-center justify-center gap-10">
                <li>
                  <Button
                    elementType={Link}
                    to="/register"
                    variant="raised"
                    size="lg"
                    radius="rounded-lg"
                    color="white"
                  >
                    <Trans message="Create a free account" />
                  </Button>
                </li>
                <li>
                  <Button
                      onClick={scrollToFeatures}
                      variant="raised"
                      size="lg"
                      radius="rounded-lg"
                      className="bg-opacity-25 text-white hover:text-primary hover:bg-opacity-50 !border-transparent"
                      color="white"
                    >
                      <Trans message="Learn more" />
                    </Button>
                </li>
              </ul>
            </div>
          </div>


          <IconButton
            size="lg"
            className="absolute bottom-5 left-0 right-0 z-30 mx-auto text-white !bg-black/25 max-md:hidden"
            aria-label={trans({message: 'View features'})}
            onClick={scrollToFeatures}
          >
            <KeyboardArrowDownIcon />
          </IconButton>

          <div className="w-full px-4">
            <div className="wow fadeInUp relative z-10 mx-auto max-w-[845px]" data-wow-delay=".25s" style={{ visibility: 'visible', animationDelay: '0.25s' }}>
              <div className="mt-24">
                <img src="/images/homepage/homepage-hero.jpg" alt="hero" className="mx-auto max-w-full rounded-t-xl rounded-tr-xl" />
              </div>
              <div className="absolute -left-56 bottom-0 z-[-1]">
                <WhiteDotsGridSvg />
              </div>
              <div className="absolute -right-24 -top-24 z-[-1]">
                <WhiteDotsGridSvg />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PrimaryFeatures({content}: ContentProps) {
  const primaryFeatures = [
    {
      IconElement: CloudUploadIcon,
      title: 'Simple Interface',
      description: 'Uncluttered and intuitive interface for managing your files.',
    },
    {
      IconElement: LinkIcon,
      title: 'Share with anyone',
      description: 'Generate links with optional expire and password protection.',
    },
    {
      IconElement: DnsIcon,
      title: 'Contextual Workspaces',
      description: 'Manage sharable virtual "drives" for your team or per project.',
    },
    {
      IconElement: ShieldIcon,
      title: 'Secure & Private',
      description: 'Advanced security settings to keep your data secure and private.',
    },
  ];

  return (
    <section className="pb-64 pt-64 dark:bg-dark lg:pb-[70px] lg:pt-128" id="primary-features">
      <div className="container">
        <div className="flex flex-wrap">
          <div className="w-full px-16">
            <div className="mx-auto mb-32 md:mb-64 max-w-[485px] text-center lg:mb-80">
              <span className="mb-2 block text-lg font-semibold text-primary">
                <Trans message="Features" />
              </span>
              <h2 className="mb-8 text-3xl md:text-4xl xl:text-[42px] tracking-tight font-bold !leading-[1.2]">
                <Trans message="The Modern Toolkit" />
              </h2>
              <p className="text-base text-body-color dark:text-dark-6">
                <Trans message="
                  All that you need to manage your files and folders in one place, or multiple,
                  with a simple and intuitive interface.
                " />
              </p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 px-24 gap-24 md:gap-48 md:grid-cols-2 lg:grid-cols-4">
          {primaryFeatures.map((feature, index) => (
            <div key={index} className="group mb-24 md:text-center">
              <div
                className="
                  relative scale-75 mb-8 md:scale-100 z-10 md:mb-24 flex h-70 w-70 items-center justify-center
                  rounded-xl bg-primary md:mx-auto
                "
              >
                <span
                  className="
                    absolute left-0 top-0 -z-[1] mb-8 flex h-70 w-70
                    rotate-[25deg] items-center justify-center rounded-xl
                    bg-primary bg-opacity-20 duration-300 group-hover:rotate-45
                  "
                />
                {feature.IconElement && <feature.IconElement className="text-white w-40 h-40" />}
              </div>
              <h4 className="mb-8 text-xl font-bold text-dark dark:text-white">
                <Trans message={feature.title} />
              </h4>
              <p className="mb-8 text-muted lg:mb-9">
                <Trans message={feature.description} />
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

function SecondaryFeatures({content}: ContentProps) {
  const secondaryFeatures = [
    {
      ImageElement: SFImageSimpleUI,
      subtitle: 'Simple UI',
      title: 'Remove the complexity, keep the flexibility.',
      description: `
        Other services get lost with feature creep and cluttered interfaces.
        We keep it simple and intuitive, so you can focus on what matters; your files.
        Our highly focused interface is designed to be easy to use, yet powerful enough to handle all your needs.
      `,
    },
    {
      ImageElement: SFImageShareWithAnyone,
      subtitle: 'Share with anyone',
      title: 'Share your files and folders with anyone you want.',
      description: `
        Share your files and folders with anyone with a simple link.
        You can set optional expiration dates and password protection to keep your files secure.
        No need to worry about large email attachments or complicated interfaces sharing anymore.
      `,
    },
    {
      ImageElement: SFImageGetCollaborative,
      subtitle: 'Get Collaborative',
      title: 'Create virtual, contextual workspaces for your team.',
      description: `
        A workspace is like a sharable "folder" that can be contributed to by multiple users,
        complete with specific permissions and settings. Workspaces don't have their own storage,
        added files are counted against the contributor's own storage quota.
      `,
    },
    // {
    //   image: 'https://source.unsplash.com/500x500/?security',
    //   subtitle: 'Secure & Private',
    //   title: 'Your data is secure and private.',
    //   description: `
    //     Your data is important, and we take that seriously.
    //     We offer various settings to keep your data secure and private.
    //     From optional password protection to advanced security settings, we have you covered.
    //   `,
    // },
  ];

  return secondaryFeatures.map((feature, index) => {
    const isEven = index % 2 === 0;
    return (
      <section
        key={`feature-${index}`}
        className={clsx(
          'flex items-center py-56 lg:py-64 lg:h-512 xl:h-680 max-w-full',
          isEven && 'bg-alt',
        )}
      >
        <div className="container relative">
          <div
            className={clsx(
              '-mx-4 flex flex-wrap items-center flex-col',
              !isEven && 'lg:flex-row',
              isEven && 'lg:flex-row-reverse',
            )}
          >
            <div className="w-full px-16 lg:w-2/5 xl:w-1/2">
              <div className="text-center mx-auto mb-32 max-w-512 lg:text-left lg:mb-0">
                <div className="mb-8 font-medium tracking-wider text-primary">
                  <Trans message={feature.subtitle} />
                </div>
                <h2 className="mb-24 text-3xl md:text-4xl xl:text-[42px] tracking-tight font-bold !leading-[1.2]">
                  <Trans message={feature.title} />
                </h2>
                <p className="text-base leading-normal xl:text-lg ">
                  <Trans message={feature.description} />
                </p>


              </div>
            </div>

            <div className="w-full px-4 lg:w-3/5 xl:w-1/2 relative self-stretch">
              <div className="mx-auto justify-center flex flex-wrap sm:-mx-4 lg:-mx-2 xl:-mx-4">

                {feature.ImageElement && <feature.ImageElement />}

              </div>
            </div>
          </div>
        </div>
      </section>
    );
  });
}

function SFImageSimpleUI() {
  return (
    <div
      className="
        relative z-0 left-0 top-0 mt-16
        md:mt-24 md:w-4/5
        lg:absolute lg:-mt-8 lg:-ml-64 lg:w-full
        xl:-mt-64 xl:-ml-180 xl:w-780
      "
    >
      <img
        src="/images/homepage/homepage-simple-ui.jpg"
        alt="An example of utility in the UI, presenting a simple and intuitive interface."
        className="mx-auto max-w-full rounded-xl drop-shadow-2xl border"
      />
    </div>
  );
}

function SFImageShareWithAnyone() {
  return (
    <div
      className="
        relative right-0 top-0 mt-64
        md:-left-48 md:mt-110 md:w-4/5
        lg:absolute lg:right-auto lg:mt-32 lg:left-56
        xl:right-0 xl:left-auto xl:-mt-32 xl:-mr-64 xl:w-680
      "
    >
      <img
        src="/images/homepage/homepage-sharing-01.jpg"
        alt="Shows a context menu with the share option."
        className="mx-auto max-w-full rounded-xl drop-shadow-2xl border"
      />
      <div
        className="
          absolute w-4/5
        "
        style={{ left: '46%', top: '-22%' }}
      >
        <img
          src="/images/homepage/homepage-sharing-02.png"
          alt="hero"
          className="mx-auto max-w-full rounded-xl drop-shadow-2xl border"
        />
      </div>
    </div>
  );
}

function SFImageGetCollaborative() {
  return (
    <div
      className="
        relative left-0 top-0 mt-64
        md:w-4/5 md:mt-80 md:-left-48
        lg:left-0 lg:absolute lg:w-440 lg:top-64 lg:-ml-44
        xl:top-32 xl:mt-0 xl:-ml-320 xl:w-720
      "
    >
      <img
        src="/images/homepage/homepage-workspaces-01.jpg"
        alt="An example of utility in the UI, presenting a simple and intuitive interface."
        className="mx-auto max-w-full rounded-xl drop-shadow-2xl border"
      />
      <div
        className="
          absolute w-4/5
          -ml-16 mt-32
          md:mt-64
          lg:mt-56
          xl:mt-0
        "
        style={{ top: '-33.3333%', left: '48%' }}
      >
        <img
          src="/images/homepage/homepage-workspaces-02.jpg"
          alt="hero"
          className="mx-auto max-w-full rounded-xl drop-shadow-2xl border"
        />
      </div>
    </div>
  );
}

function BottomCta({content}: ContentProps) {
  return (
    <section className="relative overflow-hidden bg-primary py-64 md:py-80 lg:py-128">
      <div className="container mx-auto">
        <div className="relative overflow-hidden">
          <div className="-mx-4 flex flex-wrap items-stretch">
            <div className="w-full px-4">
              <div className="mx-auto max-w-[570px] text-center">
                <h2 className="mb-12 text-3xl font-bold text-white md:text-[38px] md:leading-[1.44]">
                  <Trans message="Try it now for free, forever." />
                </h2>
                <p className="mx-auto mb-24 max-w-[515px] text-base md:text-lg leading-[1.5] text-white">
                  <Trans message="
                    Get started with our free 15GB plan, no credit card required.
                    Need more storage? Our paid plans start at $1.99/month.
                  " />
                </p>
                <Button
                  size="lg"
                  elementType={Link}
                  to="/register"
                  variant="flat"
                  color="white"
                >
                  <Trans message="Sign Up Now" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <span className="absolute left-0 top-0">
          <svg width="495" height="470" viewBox="0 0 495 470" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="55" cy="442" r="138" stroke="white" strokeOpacity="0.04" strokeWidth="50" />
            <circle cx="446" r="39" stroke="white" strokeOpacity="0.04" strokeWidth="20" />
            <path d="M245.406 137.609L233.985 94.9852L276.609 106.406L245.406 137.609Z" stroke="white" strokeOpacity="0.08" strokeWidth="12" />
          </svg>
        </span>
        <span className="absolute bottom-0 right-0">
          <svg width="493" height="470" viewBox="0 0 493 470" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="462" cy="5" r="138" stroke="white" strokeOpacity="0.04" strokeWidth="50" />
            <circle cx="49" cy="470" r="39" stroke="white" strokeOpacity="0.04" strokeWidth="20" />
            <path d="M222.393 226.701L272.808 213.192L259.299 263.607L222.393 226.701Z" stroke="white" strokeOpacity="0.06" strokeWidth="13" />
          </svg>
        </span>
      </div>
    </section>
  );
}

function FAQSection({content}: ContentProps) {
  const points = [
    {
      IconElement: ShieldIcon,
      title: 'Is the 15GB plan actually free?',
      content: `
        Yes, that plan is free forever, with no credit card required, no time limit,
        and full access to all features (API access on request only).
      `,
    },
    {
      IconElement: LockIcon,
      title: 'How secure is my data?',
      content: 'Your data is encrypted both at rest and in transit using industry-leading security protocols, ensuring strong protection.',
    },
    {
      IconElement: DevicesIcon,
      title: 'Can I sync files with my device?',
      content: 'File syncing might come in the future but you can access your files across all your devices using a web browser.',
    },
    {
      IconElement: CloudUploadIcon,
      title: 'What if I need more storage?',
      content: 'You can upgrade to any of our affordable paid plans at anytime, starting at $2/month and scaling up to 16TB.',
    },
    {
      IconElement: SupportIcon,
      title: 'What if I need help?',
      content: `
        While we're not a huge enterprise, we are here to help! Reach out to us via email and we'll get back to you as soon as possible.
      `,
    },
    {
      IconElement: StarIcon,
      title: 'Why choose this service?',
      content: `
        We built this platform to be simple, reliable, and easy to use, because storing and sharing files
        shouldn't be complicated or expensive.
      `,
    },
  ];

  return (
    <section className="relative z-20 overflow-hidden py-64 md:py-80 lg:py-128">
      <div className="container mx-auto">
        <div className="flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-80 lg:96 max-w-512 text-center">
              <h2 className="mb-8 text-3xl font-bold text-dark dark:text-white sm:text-4xl md:text-[40px] md:leading-[1.2]">
                <Trans message="Simply cloud storage without the complexity" />
              </h2>
              <p className="text-base">
                <Trans message="
                  Still not quite sure about something? Sign up to get 15GB for free to give
                  us a try or reach out to us via email for more information.
                " />
              </p>
            </div>
          </div>
        </div>
        <div className="mx-8 md:-mx-4 grid grid-cols-1 gap-24 md:gap-40 sm:grid-cols-2 md:grid-cols-3">
          {points.map((point, index) => (
            <div className="flex mb-24" key={index}>
              <div
                className="
                  mr-16 flex h-50 w-full max-w-50 items-center justify-center rounded-xl
                  bg-primary text-white sm:mr-20 sm:h-60 sm:max-w-60
                "
              >
                {point.IconElement && <point.IconElement className="w-32 h-32" />}
              </div>
              <div className="w-full">
                <h3 className="mb-6 text-xl font-semibold sm:text-lg xl:text-xl">
                  <Trans message={point.title} />
                </h3>
                <p className="text-base">
                  <Trans message={point.content} />
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="hidden md:visible">
        <span className="absolute left-4 top-4 -z-[1]">
          <PrimaryDotsGridSvg />
        </span>
        <span className="absolute bottom-4 right-4 -z-[1]">
          <PrimaryDotsGridSvg />
        </span>
      </div>
    </section>
  );
}


export function PrimaryDotsGridSvg() {
  return(
    <svg width="48" height="134" viewBox="0 0 48 134" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="45.6673" cy="132" r="1.66667" transform="rotate(180 45.6673 132)" className="fill-primary" />
      <circle cx="45.6673" cy="117.333" r="1.66667" transform="rotate(180 45.6673 117.333)" className="fill-primary" />
      <circle cx="45.6673" cy="102.667" r="1.66667" transform="rotate(180 45.6673 102.667)" className="fill-primary" />
      <circle cx="45.6673" cy="88.0001" r="1.66667" transform="rotate(180 45.6673 88.0001)" className="fill-primary" />
      <circle cx="45.6673" cy="73.3335" r="1.66667" transform="rotate(180 45.6673 73.3335)" className="fill-primary" />
      <circle cx="45.6673" cy="45.0001" r="1.66667" transform="rotate(180 45.6673 45.0001)" className="fill-primary" />
      <circle cx="45.6673" cy="16.0001" r="1.66667" transform="rotate(180 45.6673 16.0001)" className="fill-primary" />
      <circle cx="45.6673" cy="59.0001" r="1.66667" transform="rotate(180 45.6673 59.0001)" className="fill-primary" />
      <circle cx="45.6673" cy="30.6668" r="1.66667" transform="rotate(180 45.6673 30.6668)" className="fill-primary" />
      <circle cx="45.6673" cy="1.66683" r="1.66667" transform="rotate(180 45.6673 1.66683)" className="fill-primary" />
      <circle cx="31.0013" cy="132" r="1.66667" transform="rotate(180 31.0013 132)" className="fill-primary" />
      <circle cx="31.0013" cy="117.333" r="1.66667" transform="rotate(180 31.0013 117.333)" className="fill-primary" />
      <circle cx="31.0013" cy="102.667" r="1.66667" transform="rotate(180 31.0013 102.667)" className="fill-primary" />
      <circle cx="31.0013" cy="88.0001" r="1.66667" transform="rotate(180 31.0013 88.0001)" className="fill-primary" />
      <circle cx="31.0013" cy="73.3335" r="1.66667" transform="rotate(180 31.0013 73.3335)" className="fill-primary" />
      <circle cx="31.0013" cy="45.0001" r="1.66667" transform="rotate(180 31.0013 45.0001)" className="fill-primary" />
      <circle cx="31.0013" cy="16.0001" r="1.66667" transform="rotate(180 31.0013 16.0001)" className="fill-primary" />
      <circle cx="31.0013" cy="59.0001" r="1.66667" transform="rotate(180 31.0013 59.0001)" className="fill-primary" />
      <circle cx="31.0013" cy="30.6668" r="1.66667" transform="rotate(180 31.0013 30.6668)" className="fill-primary" />
      <circle cx="31.0013" cy="1.66683" r="1.66667" transform="rotate(180 31.0013 1.66683)" className="fill-primary" />
      <circle cx="16.3333" cy="132" r="1.66667" transform="rotate(180 16.3333 132)" className="fill-primary" />
      <circle cx="16.3333" cy="117.333" r="1.66667" transform="rotate(180 16.3333 117.333)" className="fill-primary" />
      <circle cx="16.3333" cy="102.667" r="1.66667" transform="rotate(180 16.3333 102.667)" className="fill-primary" />
      <circle cx="16.3333" cy="88.0001" r="1.66667" transform="rotate(180 16.3333 88.0001)" className="fill-primary" />
      <circle cx="16.3333" cy="73.3335" r="1.66667" transform="rotate(180 16.3333 73.3335)" className="fill-primary" />
      <circle cx="16.3333" cy="45.0001" r="1.66667" transform="rotate(180 16.3333 45.0001)" className="fill-primary" />
      <circle cx="16.3333" cy="16.0001" r="1.66667" transform="rotate(180 16.3333 16.0001)" className="fill-primary" />
      <circle cx="16.3333" cy="59.0001" r="1.66667" transform="rotate(180 16.3333 59.0001)" className="fill-primary" />
      <circle cx="16.3333" cy="30.6668" r="1.66667" transform="rotate(180 16.3333 30.6668)" className="fill-primary" />
      <circle cx="16.3333" cy="1.66683" r="1.66667" transform="rotate(180 16.3333 1.66683)" className="fill-primary" />
      <circle cx="1.66732" cy="132" r="1.66667" transform="rotate(180 1.66732 132)" className="fill-primary" />
      <circle cx="1.66732" cy="117.333" r="1.66667" transform="rotate(180 1.66732 117.333)" className="fill-primary" />
      <circle cx="1.66732" cy="102.667" r="1.66667" transform="rotate(180 1.66732 102.667)" className="fill-primary" />
      <circle cx="1.66732" cy="88.0001" r="1.66667" transform="rotate(180 1.66732 88.0001)" className="fill-primary" />
      <circle cx="1.66732" cy="73.3335" r="1.66667" transform="rotate(180 1.66732 73.3335)" className="fill-primary" />
      <circle cx="1.66732" cy="45.0001" r="1.66667" transform="rotate(180 1.66732 45.0001)" className="fill-primary" />
      <circle cx="1.66732" cy="16.0001" r="1.66667" transform="rotate(180 1.66732 16.0001)" className="fill-primary" />
      <circle cx="1.66732" cy="59.0001" r="1.66667" transform="rotate(180 1.66732 59.0001)" className="fill-primary" />
      <circle cx="1.66732" cy="30.6668" r="1.66667" transform="rotate(180 1.66732 30.6668)" className="fill-primary" />
      <circle cx="1.66732" cy="1.66683" r="1.66667" transform="rotate(180 1.66732 1.66683)" className="fill-primary" />
    </svg>
  );
}

export function WhiteDotsGridSvg() {
  return (
    <svg width="134" height="106" viewBox="0 0 134 106" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="1.66667" cy="104" r="1.66667" transform="rotate(-90 1.66667 104)" fill="white"></circle>
      <circle cx="16.3333" cy="104" r="1.66667" transform="rotate(-90 16.3333 104)" fill="white"></circle>
      <circle cx="31" cy="104" r="1.66667" transform="rotate(-90 31 104)" fill="white"></circle>
      <circle cx="45.6667" cy="104" r="1.66667" transform="rotate(-90 45.6667 104)" fill="white"></circle>
      <circle cx="60.3333" cy="104" r="1.66667" transform="rotate(-90 60.3333 104)" fill="white"></circle>
      <circle cx="88.6667" cy="104" r="1.66667" transform="rotate(-90 88.6667 104)" fill="white"></circle>
      <circle cx="117.667" cy="104" r="1.66667" transform="rotate(-90 117.667 104)" fill="white"></circle>
      <circle cx="74.6667" cy="104" r="1.66667" transform="rotate(-90 74.6667 104)" fill="white"></circle>
      <circle cx="103" cy="104" r="1.66667" transform="rotate(-90 103 104)" fill="white"></circle>
      <circle cx="132" cy="104" r="1.66667" transform="rotate(-90 132 104)" fill="white"></circle>
      <circle cx="1.66667" cy="89.3333" r="1.66667" transform="rotate(-90 1.66667 89.3333)" fill="white"></circle>
      <circle cx="16.3333" cy="89.3333" r="1.66667" transform="rotate(-90 16.3333 89.3333)" fill="white"></circle>
      <circle cx="31" cy="89.3333" r="1.66667" transform="rotate(-90 31 89.3333)" fill="white"></circle>
      <circle cx="45.6667" cy="89.3333" r="1.66667" transform="rotate(-90 45.6667 89.3333)" fill="white"></circle>
      <circle cx="60.3333" cy="89.3338" r="1.66667" transform="rotate(-90 60.3333 89.3338)" fill="white"></circle>
      <circle cx="88.6667" cy="89.3338" r="1.66667" transform="rotate(-90 88.6667 89.3338)" fill="white"></circle>
      <circle cx="117.667" cy="89.3338" r="1.66667" transform="rotate(-90 117.667 89.3338)" fill="white"></circle>
      <circle cx="74.6667" cy="89.3338" r="1.66667" transform="rotate(-90 74.6667 89.3338)" fill="white"></circle>
      <circle cx="103" cy="89.3338" r="1.66667" transform="rotate(-90 103 89.3338)" fill="white"></circle>
      <circle cx="132" cy="89.3338" r="1.66667" transform="rotate(-90 132 89.3338)" fill="white"></circle>
      <circle cx="1.66667" cy="74.6673" r="1.66667" transform="rotate(-90 1.66667 74.6673)" fill="white"></circle>
      <circle cx="1.66667" cy="31.0003" r="1.66667" transform="rotate(-90 1.66667 31.0003)" fill="white"></circle>
      <circle cx="16.3333" cy="74.6668" r="1.66667" transform="rotate(-90 16.3333 74.6668)" fill="white"></circle>
      <circle cx="16.3333" cy="31.0003" r="1.66667" transform="rotate(-90 16.3333 31.0003)" fill="white"></circle>
      <circle cx="31" cy="74.6668" r="1.66667" transform="rotate(-90 31 74.6668)" fill="white"></circle>
      <circle cx="31" cy="31.0003" r="1.66667" transform="rotate(-90 31 31.0003)" fill="white"></circle>
      <circle cx="45.6667" cy="74.6668" r="1.66667" transform="rotate(-90 45.6667 74.6668)" fill="white"></circle>
      <circle cx="45.6667" cy="31.0003" r="1.66667" transform="rotate(-90 45.6667 31.0003)" fill="white"></circle>
      <circle cx="60.3333" cy="74.6668" r="1.66667" transform="rotate(-90 60.3333 74.6668)" fill="white"></circle>
      <circle cx="60.3333" cy="31.0001" r="1.66667" transform="rotate(-90 60.3333 31.0001)" fill="white"></circle>
      <circle cx="88.6667" cy="74.6668" r="1.66667" transform="rotate(-90 88.6667 74.6668)" fill="white"></circle>
      <circle cx="88.6667" cy="31.0001" r="1.66667" transform="rotate(-90 88.6667 31.0001)" fill="white"></circle>
      <circle cx="117.667" cy="74.6668" r="1.66667" transform="rotate(-90 117.667 74.6668)" fill="white"></circle>
      <circle cx="117.667" cy="31.0001" r="1.66667" transform="rotate(-90 117.667 31.0001)" fill="white"></circle>
      <circle cx="74.6667" cy="74.6668" r="1.66667" transform="rotate(-90 74.6667 74.6668)" fill="white"></circle>
      <circle cx="74.6667" cy="31.0001" r="1.66667" transform="rotate(-90 74.6667 31.0001)" fill="white"></circle>
      <circle cx="103" cy="74.6668" r="1.66667" transform="rotate(-90 103 74.6668)" fill="white"></circle>
      <circle cx="103" cy="31.0001" r="1.66667" transform="rotate(-90 103 31.0001)" fill="white"></circle>
      <circle cx="132" cy="74.6668" r="1.66667" transform="rotate(-90 132 74.6668)" fill="white"></circle>
      <circle cx="132" cy="31.0001" r="1.66667" transform="rotate(-90 132 31.0001)" fill="white"></circle>
      <circle cx="1.66667" cy="60.0003" r="1.66667" transform="rotate(-90 1.66667 60.0003)" fill="white"></circle>
      <circle cx="1.66667" cy="16.3336" r="1.66667" transform="rotate(-90 1.66667 16.3336)" fill="white"></circle>
      <circle cx="16.3333" cy="60.0003" r="1.66667" transform="rotate(-90 16.3333 60.0003)" fill="white"></circle>
      <circle cx="16.3333" cy="16.3336" r="1.66667" transform="rotate(-90 16.3333 16.3336)" fill="white"></circle>
      <circle cx="31" cy="60.0003" r="1.66667" transform="rotate(-90 31 60.0003)" fill="white"></circle>
      <circle cx="31" cy="16.3336" r="1.66667" transform="rotate(-90 31 16.3336)" fill="white"></circle>
      <circle cx="45.6667" cy="60.0003" r="1.66667" transform="rotate(-90 45.6667 60.0003)" fill="white"></circle>
      <circle cx="45.6667" cy="16.3336" r="1.66667" transform="rotate(-90 45.6667 16.3336)" fill="white"></circle>
      <circle cx="60.3333" cy="60.0003" r="1.66667" transform="rotate(-90 60.3333 60.0003)" fill="white"></circle>
      <circle cx="60.3333" cy="16.3336" r="1.66667" transform="rotate(-90 60.3333 16.3336)" fill="white"></circle>
      <circle cx="88.6667" cy="60.0003" r="1.66667" transform="rotate(-90 88.6667 60.0003)" fill="white"></circle>
      <circle cx="88.6667" cy="16.3336" r="1.66667" transform="rotate(-90 88.6667 16.3336)" fill="white"></circle>
      <circle cx="117.667" cy="60.0003" r="1.66667" transform="rotate(-90 117.667 60.0003)" fill="white"></circle>
      <circle cx="117.667" cy="16.3336" r="1.66667" transform="rotate(-90 117.667 16.3336)" fill="white"></circle>
      <circle cx="74.6667" cy="60.0003" r="1.66667" transform="rotate(-90 74.6667 60.0003)" fill="white"></circle>
      <circle cx="74.6667" cy="16.3336" r="1.66667" transform="rotate(-90 74.6667 16.3336)" fill="white"></circle>
      <circle cx="103" cy="60.0003" r="1.66667" transform="rotate(-90 103 60.0003)" fill="white"></circle>
      <circle cx="103" cy="16.3336" r="1.66667" transform="rotate(-90 103 16.3336)" fill="white"></circle>
      <circle cx="132" cy="60.0003" r="1.66667" transform="rotate(-90 132 60.0003)" fill="white"></circle>
      <circle cx="132" cy="16.3336" r="1.66667" transform="rotate(-90 132 16.3336)" fill="white"></circle>
      <circle cx="1.66667" cy="45.3336" r="1.66667" transform="rotate(-90 1.66667 45.3336)" fill="white"></circle>
      <circle cx="1.66667" cy="1.66683" r="1.66667" transform="rotate(-90 1.66667 1.66683)" fill="white"></circle>
      <circle cx="16.3333" cy="45.3336" r="1.66667" transform="rotate(-90 16.3333 45.3336)" fill="white"></circle>
      <circle cx="16.3333" cy="1.66683" r="1.66667" transform="rotate(-90 16.3333 1.66683)" fill="white"></circle>
      <circle cx="31" cy="45.3336" r="1.66667" transform="rotate(-90 31 45.3336)" fill="white"></circle>
      <circle cx="31" cy="1.66683" r="1.66667" transform="rotate(-90 31 1.66683)" fill="white"></circle>
      <circle cx="45.6667" cy="45.3336" r="1.66667" transform="rotate(-90 45.6667 45.3336)" fill="white"></circle>
      <circle cx="45.6667" cy="1.66683" r="1.66667" transform="rotate(-90 45.6667 1.66683)" fill="white"></circle>
      <circle cx="60.3333" cy="45.3338" r="1.66667" transform="rotate(-90 60.3333 45.3338)" fill="white"></circle>
      <circle cx="60.3333" cy="1.66707" r="1.66667" transform="rotate(-90 60.3333 1.66707)" fill="white"></circle>
      <circle cx="88.6667" cy="45.3338" r="1.66667" transform="rotate(-90 88.6667 45.3338)" fill="white"></circle>
      <circle cx="88.6667" cy="1.66707" r="1.66667" transform="rotate(-90 88.6667 1.66707)" fill="white"></circle>
      <circle cx="117.667" cy="45.3338" r="1.66667" transform="rotate(-90 117.667 45.3338)" fill="white"></circle>
      <circle cx="117.667" cy="1.66707" r="1.66667" transform="rotate(-90 117.667 1.66707)" fill="white"></circle>
      <circle cx="74.6667" cy="45.3338" r="1.66667" transform="rotate(-90 74.6667 45.3338)" fill="white"></circle>
      <circle cx="74.6667" cy="1.66707" r="1.66667" transform="rotate(-90 74.6667 1.66707)" fill="white"></circle>
      <circle cx="103" cy="45.3338" r="1.66667" transform="rotate(-90 103 45.3338)" fill="white"></circle>
      <circle cx="103" cy="1.66707" r="1.66667" transform="rotate(-90 103 1.66707)" fill="white"></circle>
      <circle cx="132" cy="45.3338" r="1.66667" transform="rotate(-90 132 45.3338)" fill="white"></circle>
      <circle cx="132" cy="1.66707" r="1.66667" transform="rotate(-90 132 1.66707)" fill="white"></circle>
    </svg>
  );
}